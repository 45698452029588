import * as React from "react";
import {
  Button, Divider, Form, Grid, Header,
  Input, Message, Segment, Container
} from "semantic-ui-react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {
  IUser, IErrorResponse
} from "../../types";
import {t} from "../../i18n";
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from "gatsby";
import {navigateTo} from "../../helpers/router";
import PasswordStrengthBar from 'react-password-strength-bar';
import SEO from "../seo";
import Cloud from '../backgrounds/others/Cloud';

interface StateProps {
  register: IUser & IErrorResponse
  showReg: boolean
}

export function mapStateToProps(state: any): StateProps {
  return {
    register: state.registerUser,
    showReg: state.showRegister
  };
}

interface DispatchProps {
  registerUser: typeof actions.registerUser,
  showRegister: typeof actions.showRegister,
  createOrder: typeof actions.createOrder,
  getUser: typeof actions.fetchUser,
}

const mapDispatchToProps = {
  registerUser: actions.registerUser,
  showRegister: actions.showRegister,
  createOrder: actions.createOrder,
  getUser: actions.fetchUser,
}

type Props = StateProps & DispatchProps;

interface State {
  firstName: string,
  lastName: string,
  userEmail: string,
  password: string,
  repassword: string
  button: boolean
  formErrorKeys?: any
  formErrors?: any
  showError: boolean
  success: boolean
  passStrong: boolean
}

class Register extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      userEmail: '',
      password: '',
      repassword: '',
      button: false,
      formErrorKeys: [],
      formErrors: [],
      showError: false,
      success: false,
      passStrong: false
    };
  }

  componentDidMount() {
    this.props.showRegister(false);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.register !== prevProps.register) {
      let register = this.props.register;
      if (register.errors) {
        let errors = [];
        let errorKeys = [];
        for (let key in register.errors) {
          errorKeys[key] = true;
          errors.push(t(register.errors[key]));
        }
        this.setState({showError: true, formErrorKeys: errorKeys, formErrors: errors})
      }
      if (register.data) {
        this.setState({success: true});
      }
    }
  }

  hideLogin() {
    this.props.showRegister(false);
  }

  getUserAndRedirect(url='/') {
    this.props.getUser();
    navigateTo(url);
  }

  buttonForRedirect(){
    if(this.props.location.state && this.props.location.state.redirectUrl){
      return (
        <Button secondary id="btn-register" onClick={() => this.getUserAndRedirect(this.props.location.state.redirectUrl)}>
          Przejdź do zlecenia</Button>
      )
    }
  }

  handleRegisterForm(e: React.FormEvent): void {
    e.preventDefault();
    if (this.state.password === this.state.repassword) {
      let formData = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.userEmail,
        password: this.state.password,
      };
      this.setState({showError: false}, () => this.props.registerUser(formData))
    } else {
      this.setState({formErrors: [t('Hasła nie są identyczne')], formErrorKeys: {repassword: true}, showError: true})
    }
  }

  handleInputChange(e: any, data: any): void {
    // @ts-ignore
    this.setState({[data.name]: data.value});
  }

  isPasswordAcceptable(score) {
    if (score >= 1) {
      this.setState({passStrong: true})
    } else {
      this.setState({passStrong: false})
    }
  }

  render() {
    if (this.state.success)
      return (
        <Cloud>
          <Segment basic id="cloud-panel" style={{height: "100vh", display: "flex", alignItems: "center"}}>
            <Container>
              <Grid textAlign='center'>
                <Grid.Row>
                  <Grid.Column>
                    <Segment basic>
                      <Header id="register-header">Rejestracja</Header>
                      <Header id="register-subheader">przebiegła pomyślnie! <br />Dziękujemy.</Header>
                      <Button primary id="btn-register" onClick={this.getUserAndRedirect.bind(this)}>
                        Przejdź do strony głównej</Button>
                      {this.buttonForRedirect()}
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </Cloud>
      )
    else
      return (
        <Cloud>
          <Segment basic id="cloud-panel" className="register-container">
            <SEO title="Rejestracja do portalu klienta"
              description="Zarejestruj się i spersonalizuj suplement dopasowany do Twoich potrzeb. Zapisz swój skład suplementu na koncie, z późniejszą możliwością jego modyfikacji."
            />
            <Container>
              <ScrollAnimation animateIn='fadeInLeft' animateOut='fadeOutRight'>
                <div className="register-form-container">
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h1" textAlign="center">{t('Rejestracja')}</Header>
                        <Divider hidden/>
                        <Form onSubmit={this.handleRegisterForm.bind(this)}
                              error={this.state.showError}>
                          <Form.Group widths="equal">
                            <Form.Field>
                              <p>{t("imię")}</p>
                              <Input
                                placeholder={t("imię")}
                                required
                                name="firstName"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.firstName}
                              />
                            </Form.Field>
                            <Form.Field>
                              <p>{t("nazwisko")}</p>
                              <Input
                                placeholder={t("nazwisko")}
                                required
                                name="lastName"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.lastName}
                              />
                            </Form.Field>
                          </Form.Group>
                          <p>{t('e-mail')}</p>
                          <Form.Field error={this.state.formErrorKeys.email}>
                            <Input
                              placeholder={t("e-mail")}
                              name="userEmail"
                              type="email"
                              onChange={this.handleInputChange.bind(this)}
                              value={this.state.userEmail}
                            />
                          </Form.Field>

                          <Form.Group widths='equal'>
                            <Form.Field error={this.state.formErrorKeys.password}>
                              <p>{t('hasło')}</p>
                              <Input
                                placeholder={t("hasło")}
                                name="password"
                                type="password"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.password}
                              />
                              <PasswordStrengthBar password={this.state.password}
                                                  shortScoreWord={'hasło jest za krótkie'}
                                                  minLength={6}
                                                  onChangeScore={(score)=> this.isPasswordAcceptable(score)}
                              />
                            </Form.Field>
                            <Form.Field error={this.state.formErrorKeys.repassword}>
                              <p>{t('powtórz hasło')}</p>
                              <Input
                                placeholder={t("powtórz hasło")}
                                required
                                name="repassword"
                                type="password"
                                onChange={this.handleInputChange.bind(this)}
                                value={this.state.repassword}
                              />
                            </Form.Field>
                          </Form.Group>
                          <Button primary>
                            {t('Dalej')}
                          </Button>
                          &nbsp;<Link to="/login">Logowanie</Link>
                          <Message
                            error
                            header={t("Popraw błędy formularza")}
                            list={this.state.formErrors}
                          />
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </ScrollAnimation>
            </Container>
          </Segment>
        </Cloud>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);